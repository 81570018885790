import { Element, Frame } from '@craftjs/core';
import { decode } from 'js-base64';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

import { LayoutSingleColumn, MobileBottomNavigation, Page } from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FollowingSection from './components/FollowingSection/FollowingSection';
import NewBlackSection from './components/NewBlackSection';
import { NewCarouselSection } from './components/NewCarouselSection';
import { NewFeaturedSection } from './components/NewFeaturedSection';
import { NewIconsSection } from './components/NewIconsSection';
import NewInstagramSection from './components/NewInstagramSection';
import NewListingsSection from './components/NewListingsSection';
// import NewLookingForSection from './components/NewLookingForSection';
import NewMatrixSection from './components/NewMatrixSection';
import NewSampleSaleSection from './components/NewSampleSaleSection';
import NewTrendingNowSection from './components/NewTrendingNowSection';
import css from './CustomLandingPage.module.css';
import { CustomEditor } from 'containers/EditablePages/EditablePages';
import { useGetEditablePage } from 'containers/EditablePages/EditablePages.hooks';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { useGeolocation } from 'hooks/useGeolocation';
import { Listing } from 'models/ListingModels';
import { sendGa4Event } from 'util/GA4Events';

const CustomLandingPage = () => {
  const { userGeolocation, isLoading: isLoadingUser } = useGeolocation();
  console.log('userGeolocation', userGeolocation);

  const scrollingDisabled = useIsScrollingDisabled();

  useEffect(() => {
    sendGa4Event('home_page_visited', {});
  }, []);

  const { data: listingsData } = useGetListings({});
  const allListings: Listing[] = listingsData?.pages.flatMap(page => page.listings) || [];
  const latestListings = allListings.slice(0, 24);

  const carouselRef = useRef<HTMLDivElement>(null);

  if (isLoadingUser) {
    return null;
  }
  try {
    if (userGeolocation === 'BG') {
      return <EditablePage key="nold-bg" pageId="nold-bg" />;
    } else {
      return <EditablePage key="home" pageId="home" />;
    }
  } catch (error) {
    console.error('Error fetching landing page', error);
  }

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<MobileBottomNavigation />}>
        <div className={css.wrapper}>
          <FollowingSection />
          <NewMatrixSection
            onClickYes={() => {
              carouselRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
          {/* <NewLookingForSection /> */}
          <NewCarouselSection
            ref={carouselRef}
            title="Luxury Faves"
            buttonText="Shop all"
            collectionId={6}
            subtitle="PRE-LOVED FAVES"
            items={[
              {
                title: 'Hermès',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Hermes_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Hermes_blur.webp',
                link: `?brandIds=4058`,
              },
              {
                title: 'Bottega Veneta',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Bottega_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Bottega_blur.webp',
                link: `?brandIds=3509`,
              },
              {
                title: 'Loewe',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Loewe_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Loewe_blur.webp',
                link: `?brandIds=2878`,
              },
              {
                title: 'Gucci',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Gucci_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Gucci_blur.webp',
                link: `?brandIds=4021`,
              },
              {
                title: 'Prada',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Prada_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Prada_blur.webp',
                link: `?brandIds=2857,4836,4837`,
              },
              {
                title: 'Celine',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Celine_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Celine_blur.webp',
                link: `?brandIds=7287`,
              },
              {
                title: 'The Row',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - The Row_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - The Row_blur.webp',
                link: `?brandIds=2957`,
              },
              {
                title: 'Dior',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Dior_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Dior_blur.webp',
                link: `?brandIds=6375`,
              },
              {
                title: 'Miu Miu',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Miu Miu_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Miu Miu_blur.webp',
                link: `?brandIds=4561`,
              },
              {
                title: 'Tory Burch',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Tory Burch_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Tory Burch_blur.webp',
                link: `?brandIds=2855`,
              },
              {
                title: 'Louis Vuitton',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Louis Vuitton_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Louis Vuitton_blur.webp',
                link: `?brandIds=4380`,
              },
            ]}
          />
          <NewCarouselSection
            title="Hight Street"
            collectionId={7}
            subtitle="PRE-LOVED FAVES"
            buttonText="Shop all"
            items={[
              {
                title: 'Massimo Dutti',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Massimo Dutti_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Massimo Dutti_blur.webp',
                link: `?brandIds=5732`,
              },
              {
                title: 'Nike',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - NIke_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - NIke_blur.webp',
                link: `?brandIds=3152`,
              },
              {
                title: 'Self-Portrait',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Self Protrait_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Self Protrait_blur.webp',
                link: `?brandIds=5047`,
              },
              {
                title: 'Adidas',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Adidas_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Adidas_blur.webp',
                link: `?brandIds=3153`,
              },
              {
                title: 'Ganni',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Ganni_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Ganni_blur.webp',
                link: `?brandIds=2865`,
              },
              {
                title: 'Samsoe and Samsoe',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Samsoe and Samsoe_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Samsoe and Samsoe_blur.webp',
                link: `?brandIds=7717,2932`,
              },
              {
                title: 'Reformation',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Reformation_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Reformation_blur.webp',
                link: `?brandIds=3078`,
              },
              {
                title: 'Max Mara',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Max Mara_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Max Mara_blur.webp',
                link: `?brandIds=2864`,
              },
              {
                title: 'Sandro',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Sandro_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Sandro_blur.webp',
                link: `?brandIds=2858`,
              },
            ]}
          />
          <NewTrendingNowSection />
          <NewIconsSection />
          <NewSampleSaleSection />
          <NewBlackSection
            sentences={[
              'AI LISTING? THE FASTEST GLOBALLY',
              '100% PAYOUT? YES!',
              "BRANDS' SAMPLE SALES",
              'LOVE FOR THE PLANET',
            ]}
            ctaText="Learn more"
            ctaLink="https://intercom.help/thenold/en/"
          >
            THE NOLD IS WHERE
            <br />
            YOU{' '}
            <span className="px-1 bg-custom-green text-black">
              <span className="line-through">SHOP</span> SCALE
            </span>{' '}
            THE
            <br />
            CHANGE WE NEED
          </NewBlackSection>
          <NewListingsSection listings={latestListings} className="py-10" />
          <NewFeaturedSection />
          <NewBlackSection
            sentences={[
              '360 SOLUTIONS FLEXIBILITY',
              'EARN FROM EVERY RESALE',
              'PRE-LOVED MARKET INSIGHTS',
              'BE A CIRCULARITY PIONEER',
            ]}
            ctaText="Learn more"
            ctaLink="https://www.forbes.com/sites/brookerobertsislam/2024/07/17/how-startup-the-nold-uses-ai--sustainability-to-drive-gen-z-resale/"
          >
            THE NOLD FOR BRANDS
          </NewBlackSection>
          <NewInstagramSection className="pb-10" />
        </div>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

const EditablePage = ({ pageId }: { pageId: string }) => {
  const isScrollingDisabled = useIsScrollingDisabled();
  const { data: page, isLoading, isError, error } = useGetEditablePage(pageId);

  if (isLoading) {
    return null;
  }
  if (isError) {
    throw new Error(`Failed to fetch page ${pageId}`, { cause: error });
  }

  return (
    <Page title="THE NOLD" scrollingDisabled={isScrollingDisabled}>
      <CustomEditor>
        <div className="grid grid-cols-[1fr_auto] w-screen h-screen">
          <LayoutSingleColumn
            topbar={<TopbarContainer />}
            footer={<MobileBottomNavigation />}
            footerClassName="w-full overflow-x-hidden"
            className="w-full"
            mainColumnClassName="w-full overflow-x-hidden"
          >
            <Frame json={decode(page?.content ?? '')}>
              <Element
                canvas
                is="div"
                className="craftjs-renderer min-h-px w-full overflow-x-hidden"
                id="root"
              ></Element>
            </Frame>
          </LayoutSingleColumn>
        </div>
      </CustomEditor>
    </Page>
  );
};

export default CustomLandingPage;
